<template>
  <div>
    <!-- <title-top>实名认证</title-top> -->
    <div class="header">
      <div class="w100 bg-white" style="height:10px;"></div>
      <div class="w100 row bg-white align-center">
        <img class="imgsize44 ml40" src="~images/fanhui.png" alt @click="onBack" />
        <div class="flex1 row align-center position_r br15 bg-white search ml30 mr40">
          <i class="shuxian"></i>
          <input
            class="font28 f-999999 br15 bg-white position_a search-box"
            v-model="q"
            type="text"
            placeholder="查询或搜索订单编号"
          />
          <div class="column position_a flex-center searchbg" @click="onBtn">
            <img class="searchbtn" src="~images/sousuo2.png" />
          </div>
        </div>
      </div>
      <div
        class="row align-center bg-white font32 f-666666"
        style="border-bottom: 1px solid #E6E6E6;"
      >
        <div
          class="tab column flex1 flex-end"
          :class="{active1: currentTab == 0}"
          @click="onTab(0)"
          style="height:40px;"
        >
          <span>淘宝</span>
          <div class="underline mt8" :class="{active2: currentTab == 0}"></div>
        </div>
        <div
          class="tab column flex1 flex-end"
          @click="onTab(1)"
          :class="{active1: currentTab == 1}"
          style="height:40px;"
        >
          <span>天猫</span>
          <div class="underline mt8" :class="{active2: currentTab == 1}"></div>
        </div>
        <div
          class="tab column flex1 flex-end"
          @click="onTab(2)"
          :class="{active1: currentTab == 2}"
          style="height:40px;"
        >
          <span>京东</span>
          <div class="underline mt8" :class="{active2: currentTab == 2}"></div>
        </div>
        <div
          class="tab column flex1 flex-end"
          @click="onTab(3)"
          :class="{active1: currentTab == 3}"
          style="height:40px;"
        >
          <span>拼多多</span>
          <div class="underline mt8" :class="{active2: currentTab == 3}"></div>
        </div>
        <div
          class="tab column flex1 flex-end"
          @click="onTab(4)"
          :class="{active1: currentTab == 4}"
          style="height:40px;"
        >
          <span>I购优品</span>
          <div class="underline mt8" :class="{active2: currentTab == 4}"></div>
        </div>
      </div>
      <!--
    <div class="row align-center h80 bg-white font28 f-333333">
        <span class="flex1 row flex-center">综合</span>
        <span class="flex1 row flex-center">最新</span>
        <span class="flex1 row flex-center">销量</span>
        <div class="flex1 row flex-center">
            <span>到手价</span>
            <img class="imgsize32" src="~images/default.png" alt="">
        </div>
    </div>
      -->
    </div>
    <scroll
      class="content"
      ref="scroll"
      @pullingUp="loadMore"
      :data="list"
      :pull-up-load="true"
      :probe-type="3"
    >
      <good-list isJd :list="list" @detail="onDetail"></good-list>
    </scroll>
  </div>
</template>

<script>
// @ is an alias to /src
import Scroll from "@/components/common/scroll/Scroll";
import GoodList from "@/components/content/goodList/GoodList";
import {
  tbk_goods_search,
  jingdong_products,
  pinduoduo_goods_search
} from "network/igou.js";
import { product_search } from "network/igou2.js";

export default {
  name: "Search",
  data() {
    return {
      list: [],
      q: decodeURIComponent(this.$route.query.q || "") || "",
      page: 1,
      currentTab: this.$route.query.index || 0
    };
  },
  created() {
    this.get_tbk_goods_search();
  },
  methods: {
    onDetail(item) {
      let goods_id = "";
      if (this.currentTab == 4) {
        this.$router.push({
          path: "/zydetail",
          query: {
            id: item.id
          }
        });
      } else {
        if (this.currentTab == 0 || this.currentTab == 1) {
          goods_id = item.num_iid;
        } else if (this.currentTab == 2) {
          goods_id = item.skuId;
        } else if (this.currentTab == 3) {
          goods_id = item.goods_id;
        }
        this.$router.push({
          path: "/detail",
          query: {
            goods_id: goods_id,
            index: this.currentTab,
            item: JSON.stringify(item),
            q: this.q
          }
        });
      }
    },
    onTab(index) {
      if (this.currentTab == index) {
        return;
      }
      this.page = 1;
      this.list = [];
      this.currentTab = index;
      this.get_tbk_goods_search();
    },
    onBtn() {
      this.page = 1;
      this.list = [];
      this.get_tbk_goods_search();
    },
    onBack() {
      this.$router.back();
    },
    loadMore() {
      this.page = this.page + 1;
      this.get_tbk_goods_search();
    },
    get_tbk_goods_search() {
      let list = [];
      if (this.currentTab == 0) {
        tbk_goods_search(this.q, this.page).then(res => {
          list = res.result_list;
          this.list.push(...list);
          this.$refs.scroll.finishPullUp();
        });
      } else if (this.currentTab == 1) {
        tbk_goods_search(this.q, this.page, 20, true).then(res => {
          list = res.result_list;
          this.list.push(...list);
          this.$refs.scroll.finishPullUp();
        });
      } else if (this.currentTab == 2) {
        jingdong_products(this.q, this.page).then(res => {
          console.log(res);
          list = res;
          this.list.push(...list);
          this.$refs.scroll.finishPullUp();
        });
      } else if (this.currentTab == 3) {
        pinduoduo_goods_search(this.q, this.page).then(res => {
          console.log(res);
          list = res.data.goods_list;
          this.list.push(...list);
          this.$refs.scroll.finishPullUp();
        });
      } else if (this.currentTab == 4) {
        //TODO
        product_search(this.q, this.page).then(res => {
          console.log(res);
          list = res.data;
          this.list.push(...list);
          this.$refs.scroll.finishPullUp();
        });
      }
    }
  },
  components: {
    GoodList,
    Scroll
  }
};
</script>
<style  scoped>
.active1 {
  color: #1a1a1a;
  font-weight: bold;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 10;
}
.content {
  position: absolute;
  top: 88px;
  bottom: 49px;
  left: 0;
  right: 0;
  z-index: 1;
}
.search {
  box-sizing: border-box;
  border: 0.013333rem solid #f62341;
  width: 8.133333rem;
  height: 0.826667rem;
}
.shuxian {
  position: absolute;
  left: 0.373333rem;
  top: 0.213333rem;
  height: 0.373333rem;
  width: 0.026667rem;
  background-color: #f62341;
}
.search-box {
  box-sizing: border-box;
  left: 0.6rem;
  border: none;
  outline: none;
  cursor: pointer;
  width: 6rem;
  height: 0.746667rem;
}
.searchbg {
  right: 0;
}
.searchbtn {
  width: 1.306667rem;
  height: 0.8rem;
}
.underline {
  height: 0.053333rem;
  width: 0.866667rem;
  background-color: #ffffff;
}
.active2 {
  background-color: #f62341;
}
</style>
